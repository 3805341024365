import React from "react";
import ReactDOM from "react-dom";

const App = () => (
  <div className="container">
    <img src="/logo.png" alt="Logo Mon Petit Monde" className="logo" />
    <h1 className="title">Mon Petit Monde</h1>
    <p className="subtitle">En cours de construction</p>
  </div>
);

ReactDOM.render(<App />, document.getElementById("root"));
